import React from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import {
  ReviewResult,
  GroundTruthField,
} from "src/store/modelLifeCycleGroundTruthSlice";

interface ReviewDecisionSelectProps {
  groundTruthField: GroundTruthField;
  shouldShowError: boolean;
  isAnswerMatch: (field: GroundTruthField, many: boolean) => boolean;
  isFieldMultiSelected: (field: GroundTruthField) => boolean;
  isAnswerInadequate: (answer: string) => boolean;
  getLabel: (
    field: GroundTruthField,
    type: ReviewResult,
    matching: boolean,
  ) => string;
  getConfirmedAnswer: (
    field: GroundTruthField,
    value: string,
    many: boolean,
  ) => any;
  onFieldConfirmedAnswerChange: (
    fieldIndex: number,
    confirmedAnswer: any,
    reviewResult: string,
  ) => void;
  fieldIndex: number;
}

const ReviewDecisionSelect: React.FC<ReviewDecisionSelectProps> = ({
  groundTruthField,
  shouldShowError,
  isAnswerMatch,
  isFieldMultiSelected,
  isAnswerInadequate,
  getLabel,
  getConfirmedAnswer,
  onFieldConfirmedAnswerChange,
  fieldIndex,
}) => {
  const isConfirmedAnswerValid = (answer: string | string[]) => {
    if (typeof answer === "string") {
      return answer && !answer.toLowerCase().includes("neither");
    } else {
      return answer.length > 0;
    }
  };

  return (
    <Select
      width="200px"
      value={
        groundTruthField.reviewResult === ReviewResult.BOTH_CORRECT
          ? ReviewResult.BOTH_CORRECT
          : groundTruthField.reviewResult === ReviewResult.NEITHER
            ? ReviewResult.NEITHER
            : groundTruthField.confirmedAnswer
      }
      onChange={(value) => {
        onFieldConfirmedAnswerChange(
          fieldIndex,
          getConfirmedAnswer(
            groundTruthField,
            value,
            isFieldMultiSelected(groundTruthField),
          ),
          value,
        );
      }}
      placeholder="Choose output..."
      size="medium"
      error={
        shouldShowError &&
        !isConfirmedAnswerValid(groundTruthField.confirmedAnswer)
      }
      errorMessage={
        shouldShowError &&
        !isConfirmedAnswerValid(groundTruthField.confirmedAnswer) &&
        "please select an answer"
      }
    >
      {!isAnswerInadequate(groundTruthField.llmAnswer) && (
        <SelectOption
          key={groundTruthField.llmAnswer}
          value={groundTruthField.llmAnswer}
          label={getLabel(
            groundTruthField,
            ReviewResult.LLM_CORRECT,
            isAnswerMatch(
              groundTruthField,
              isFieldMultiSelected(groundTruthField),
            ),
          )}
        />
      )}
      {!isAnswerMatch(
        groundTruthField,
        isFieldMultiSelected(groundTruthField),
      ) &&
        !isAnswerInadequate(groundTruthField.userAnswer) && (
          <SelectOption
            key={groundTruthField.userAnswer}
            value={groundTruthField.userAnswer}
            label={getLabel(
              groundTruthField,
              ReviewResult.HUMAN_CORRECT,
              false,
            )}
          />
        )}
      {!isAnswerInadequate(groundTruthField.llmAnswer) &&
        !isAnswerInadequate(groundTruthField.userAnswer) &&
        groundTruthField.llmAnswer !== groundTruthField.userAnswer && (
          <SelectOption
            key={ReviewResult.BOTH_CORRECT}
            value={ReviewResult.BOTH_CORRECT}
            label={getLabel(groundTruthField, ReviewResult.BOTH_CORRECT, false)}
          />
        )}
      <SelectOption
        key={ReviewResult.INADEQUATE}
        value={ReviewResult.INADEQUATE}
        label={getLabel(
          groundTruthField,
          ReviewResult.INADEQUATE,
          isAnswerInadequate(groundTruthField.llmAnswer),
        )}
      />
      <SelectOption
        key={ReviewResult.NEITHER}
        value={ReviewResult.NEITHER}
        label={getLabel(groundTruthField, ReviewResult.NEITHER, false)}
      />
    </Select>
  );
};

export default ReviewDecisionSelect;
