import React, { useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Divider from "@amzn/meridian/divider";
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import Icon from "@amzn/meridian/icon";
import chevronLeftLargeToken from "@amzn/meridian-tokens/base/icon/chevron-left-large";
import chevronRightLargeToken from "@amzn/meridian-tokens/base/icon/chevron-right-large";
import exportSmallToken from "@amzn/meridian-tokens/base/icon/export-small";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  Field,
  FieldType,
  setModelName,
  setSelectedModelId,
} from "src/store/modelManagementSlice";
import meridianColors from "@amzn/meridian-tokens/base/color";
import { getClarification } from "src/store/dataExtractionSlice";
import "./modelGroundTruthReview.scss";
import { createSearchRegExp } from "src/helpers";
import {
  highlightText,
  INADEQUATE_INFORMATION,
  isMultipleAnswerEqual,
  moveToTextTop,
  removeHighlightedText,
} from "src/components/audit/helpers";
import { compareStringsIgnoreCase } from "src/utils/stringUtil";
import Loader from "@amzn/meridian/loader";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import {
  getGroundTruthData,
  getGroundTruthDataListView,
  GroundTruthDataListView,
  GroundTruthField,
  ReviewStatus,
  updateGroundTruthData,
  setFieldConfirmedAnswer,
  resetGroundTruthReviewPage,
} from "src/store/modelLifeCycleGroundTruthSlice";
import { ReviewResult } from "src/store/modelLifeCycleGroundTruthSlice";
import Input from "@amzn/meridian/input";
import ReviewDecisionSelect from "src/components/validationWorkflow/groundTruthReview/reviewDecisionSelect";
import BlindReviewDecisionSelect from "src/components/validationWorkflow/groundTruthReview/blindReviewDecisionSelect";
import AnswerCells from "src/components/validationWorkflow/groundTruthReview/answerCells";

const ModelGroundTruthReview = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { token, alias } = useAppSelector((state) => state.user);
  const {
    groundTruthDataListView,
    selectedGroundTruthData,
    getListViewLoading,
    updateGroundTruthDataLoading,
    isBlind,
  } = useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);
  const { selectedModel } = useAppSelector((state) => state.modelSlice);

  const { modelId, sourceId } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const searchRegExp = createSearchRegExp(searchQuery);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [doesClarificationExist, setDoesClarificationExist] = useState(true);
  const [groundTruthListIndex, setgroundTruthListIndex] = useState(0);

  useEffect(() => {
    if (token && modelId && sourceId) {
      dispatch(getGroundTruthData({ modelId: modelId, sourceId: sourceId }));
    }
  }, [modelId, sourceId, token]);

  useEffect(() => {
    if (token && modelId && getListViewLoading === "idle") {
      dispatch(getGroundTruthDataListView(modelId));
    }
  }, [modelId, token, getListViewLoading]);

  useEffect(() => {
    if (modelId) {
      dispatch(setSelectedModelId(modelId));
    }
  }, [modelId]);

  useEffect(() => {
    if (getListViewLoading === "fulfilled" && sourceId) {
      setgroundTruthListIndex(
        groundTruthDataListView.findIndex(
          (groundTruthDataView) => groundTruthDataView.sourceId === sourceId,
        ),
      );
    } else if (getListViewLoading === "rejected") {
      navigate(`/validationWorkflow/${modelId}`);
    }
  }, [getListViewLoading, sourceId]);

  useEffect(() => {
    if (updateGroundTruthDataLoading === "fulfilled" && sourceId) {
      dispatch(resetGroundTruthReviewPage());
      const next = findNextUnreviewedCase(groundTruthDataListView, sourceId);
      if (next) {
        navigate(`/validationWorkflow/${modelId}/${next.sourceId}`);
      } else {
        navigate(`/validationWorkflow/${modelId}`);
        dispatch(
          createToast({
            type: "success",
            message:
              "All ground truth have finished reviewed, redirect to list view page...",
            timeout: TOAST_TIMEOUT,
          }),
        );
      }
    }
  }, [updateGroundTruthDataLoading, sourceId]);

  const [randomizedFieldOutputOrders, setRandomizedFieldOutputOrders] =
    useState<boolean[]>([]);
  useEffect(() => {
    // for each filed, generate a random true/false value, example reuslt list can be [true, false, ...]
    setRandomizedFieldOutputOrders(
      selectedGroundTruthData.fields.map(() => Math.random() < 0.5),
    );
  }, []);

  const isAnswerMatch = (field: GroundTruthField, many: boolean) => {
    if (
      isAnswerInadequate(field.userAnswer) ||
      isAnswerInadequate(field.llmAnswer)
    ) {
      return false;
    }
    return (
      compareStringsIgnoreCase(field.userAnswer, field.llmAnswer) ||
      (many && isMultipleAnswerEqual(field.userAnswer, field.llmAnswer))
    );
  };

  const findNextUnreviewedCase = (
    list: GroundTruthDataListView[],
    sourceId?: string,
  ) => {
    return list
      .filter(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.sourceId !== sourceId,
      )
      .find(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.status === ReviewStatus.UNREVIEWED,
      );
  };

  const isAnswerInadequate = (answer: string) => {
    return (
      !answer ||
      compareStringsIgnoreCase(answer, "none") ||
      compareStringsIgnoreCase(answer, INADEQUATE_INFORMATION)
    );
  };

  const isConfirmedAnswerValid = (answer: string | string[]) => {
    if (typeof answer === "string") {
      return answer && !compareStringsIgnoreCase(answer, ReviewResult.NEITHER);
    } else {
      return answer.length > 0;
    }
  };

  const getFinalAnswer = (fieldIndex: number) => {
    const auditField = selectedGroundTruthData.fields[fieldIndex];

    const many = Object.values(selectedModel.fields).find(
      (label: Field) => label.name === auditField.fieldName,
    )!.many;

    const isFreeTextType =
      Object.values(selectedModel.fields).find(
        (label: Field) => label.name === auditField.fieldName,
      )!.type === FieldType.freeText;

    let value = auditField.confirmedAnswer;

    if (many) {
      if (typeof auditField.confirmedAnswer === "string") {
        if (auditField.confirmedAnswer.includes("|")) {
          value = auditField.confirmedAnswer.split("|");
        } else {
          value = [auditField.confirmedAnswer as string];
        }
      }
    } else {
      value = auditField.confirmedAnswer as string;
    }

    return isFreeTextType ? (
      <Input
        value={
          typeof value === "string"
            ? value === ReviewResult.NEITHER
              ? ""
              : value
            : value.join(",")
        }
        onChange={(answer) => {
          dispatch(
            setFieldConfirmedAnswer({
              fieldIndex: fieldIndex,
              confirmedAnswer: answer,
              reviewResult: ReviewResult.NEITHER,
            }),
          );
        }}
        type="text"
        placeholder="Enter answer..."
      ></Input>
    ) : (
      <Select
        value={value}
        onChange={(answer) => {
          dispatch(
            setFieldConfirmedAnswer({
              fieldIndex: fieldIndex,
              confirmedAnswer: answer,
              reviewResult: ReviewResult.NEITHER,
            }),
          );
        }}
        placeholder={"Choose"}
        size="medium"
        width={"200px"}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        error={
          shouldShowError && !isConfirmedAnswerValid(auditField.confirmedAnswer)
        }
        errorMessage={
          shouldShowError &&
          !auditField.confirmedAnswer &&
          "please select an answer"
        }
      >
        {Object.values(selectedModel.fields)
          .find((label: Field) => label.name === auditField.fieldName)
          ?.options.filter((option) => searchRegExp.test(option))
          .map((option) => <SelectOption value={option} label={option} />)}
      </Select>
    );
  };

  const getLabel = (
    groundTruthField: GroundTruthField,
    type: ReviewResult,
    matching: boolean,
  ): string => {
    switch (type) {
      case ReviewResult.BOTH_CORRECT:
        return ReviewResult.BOTH_CORRECT;
      case ReviewResult.LLM_CORRECT:
        return matching
          ? `${getAnswerLowerCaseAndSeparatebyComma(groundTruthField.llmAnswer)}${!isBlind ? " -- User/LLM" : ""}`
          : `${getAnswerLowerCaseAndSeparatebyComma(groundTruthField.llmAnswer)}${!isBlind ? " -- LLM" : ""}`;
      case ReviewResult.INADEQUATE:
        return INADEQUATE_INFORMATION;
      case ReviewResult.HUMAN_CORRECT:
        return `${getAnswerLowerCaseAndSeparatebyComma(groundTruthField.userAnswer)}${!isBlind ? " -- User" : ""}`;
      default:
        return "Others";
    }
  };

  const getConfirmedAnswer = (
    groundTruthField: GroundTruthField,
    value: string,
    many: boolean,
  ) => {
    if (value === ReviewResult.BOTH_CORRECT) {
      return groundTruthField.llmAnswer;
    } else if (value === ReviewResult.NEITHER) {
      return many ? [] : ReviewResult.NEITHER;
    } else {
      return value;
    }
  };

  const showExtraOptions = (groundTruthField: GroundTruthField) => {
    return (
      groundTruthField.confirmedAnswer &&
      (groundTruthField.confirmedAnswer === ReviewResult.NEITHER ||
        (groundTruthField.confirmedAnswer !== groundTruthField.userAnswer &&
          groundTruthField.confirmedAnswer !== groundTruthField.llmAnswer &&
          groundTruthField.confirmedAnswer !== INADEQUATE_INFORMATION))
    );
  };

  const getAnswerLowerCaseAndSeparatebyComma = (answer: string) => {
    if (answer.includes("|")) {
      return answer.split("|").join(", ");
    }
    return answer.toLocaleLowerCase();
  };

  const isFieldMultiSelected = (groundTruthField: GroundTruthField) => {
    return (
      Object.values(selectedModel.fields).find(
        (field) => field.name === groundTruthField.fieldName,
      )?.many || false
    );
  };

  const saveAuditDataButton = () => {
    {
      return updateGroundTruthDataLoading === "pending" ? (
        <Button size="large">
          <Loader size={"small"} />
        </Button>
      ) : (
        <Button
          size="large"
          onClick={() => {
            if (
              !selectedGroundTruthData.fields.some(
                (groundTruthfield) =>
                  !isConfirmedAnswerValid(groundTruthfield.confirmedAnswer),
              )
            ) {
              dispatch(
                updateGroundTruthData({
                  groundTruthData: selectedGroundTruthData,
                  reviewer: alias,
                }),
              );
              setShouldShowError(false);
            } else {
              setShouldShowError(true);
            }
          }}
          data-cy={"save-and-next__button"}
        >
          Save & Next
        </Button>
      );
    }
  };

  return (
    <Column spacingInset={"400"} spacing={"none"}>
      <BreadcrumbGroup>
        <Breadcrumb onClick={() => navigate(`/validationWorkflow`)}>
          Validation workflow
        </Breadcrumb>
        <Breadcrumb onClick={() => navigate(`/validationWorkflow/${modelId}`)}>
          {selectedModel.name} - Processed
        </Breadcrumb>
        <Breadcrumb>{sourceId}</Breadcrumb>
      </BreadcrumbGroup>
      <Row widths={["80%", "20%"]}>
        <Row spacing={"400"}>
          <div
            onClick={() => {
              if (groundTruthDataListView[groundTruthListIndex - 1]) {
                navigate(
                  `/validationWorkflow/${modelId}/${groundTruthDataListView[groundTruthListIndex - 1].sourceId}`,
                );
              } else {
                navigate(`/validationWorkflow/${modelId}`);
              }
            }}
          >
            <Column spacingInset={"300"} className="icon">
              <Icon tokens={chevronLeftLargeToken} />
            </Column>
          </div>
          <div
            onClick={() => {
              if (groundTruthDataListView[groundTruthListIndex + 1]) {
                navigate(
                  `/validationWorkflow/${modelId}/${groundTruthDataListView[groundTruthListIndex + 1].sourceId}`,
                );
              } else {
                navigate(`/validationWorkflow/${modelId}`);
              }
            }}
          >
            <Column spacingInset={"300"} className="icon">
              <Icon tokens={chevronRightLargeToken} />
            </Column>
          </div>
          <Text type="h500">
            <p className="weight">
              Source ID: {selectedGroundTruthData.sourceId}
            </p>
          </Text>
          <Icon tokens={exportSmallToken} />
          <Text>
            <p className="weight">Status: {selectedGroundTruthData.status}</p>
          </Text>
          {selectedGroundTruthData.status === ReviewStatus.REVIEWED && (
            <Text>
              <p className="weight">
                Reviewed by: {selectedGroundTruthData.reviewer}
              </p>
            </Text>
          )}
        </Row>
        <Row alignmentHorizontal="end" widths={["60%", "40%"]}>
          <Row widths={"fill"} alignmentHorizontal="center">
            {saveAuditDataButton()}
          </Row>
        </Row>
      </Row>
      <Divider></Divider>
      <Row
        widths={["35%", "fit", "65%"]}
        alignmentVertical="top"
        spacing={"none"}
      >
        <Column height="76vh" overflowY="auto" spacingInset={"0 400 0 400"}>
          <Text
            id="incident-description-container"
            fontFamily="amazonEmber"
            type="b500"
            color="primary"
          >
            {!doesClarificationExist && (
              <Column spacingInset={"500 0 0 0"}>
                <Alert type="warning">No phrase identified</Alert>
              </Column>
            )}
            <p id="incident-description" className="paragraph-style">
              {selectedGroundTruthData.sourceText}
            </p>
          </Text>
        </Column>
        <Column className="audit-review-split-line"></Column>
        <Column height="76vh" overflowY="auto">
          <Table showDividers={true} fixHeaderRows={true} headerRows={1}>
            <TableRow backgroundColor={meridianColors.colorGray50}>
              <TableCell width={"1%"}></TableCell>
              <TableCell width={"20%"}>
                <Text type="h200">Fields</Text>
              </TableCell>
              <TableCell width={"20%"}>
                {isBlind ? (
                  <Text type="h200">Source 1</Text>
                ) : (
                  <Text type="h200">Austin Output</Text>
                )}
              </TableCell>
              <TableCell width={"20%"}>
                {isBlind ? (
                  <Text type="h200">Source 2</Text>
                ) : (
                  <Text type="h200">LLM Output</Text>
                )}
              </TableCell>
              <TableCell width={"20%"} alignmentHorizontal={"center"}>
                <Text type="h200">Review Decision</Text>
              </TableCell>
              <TableCell width={"20%"}></TableCell>
            </TableRow>
            {selectedGroundTruthData.fields.map((groundTruthField, index) => {
              return (
                <TableRow
                  highlightOnHover
                  onMouseEnter={() => {
                    // set timeout to solve the UI mismatch caused by
                    // clarificationExist variable compete when hover mouse enter and leave
                    setTimeout(() => {
                      if (groundTruthField.llmClarification.length > 0) {
                        highlightText(
                          getClarification(groundTruthField.llmClarification),
                        );
                      } else {
                        moveToTextTop();
                        setDoesClarificationExist(false);
                      }
                    }, 100);
                  }}
                  onMouseLeave={() => {
                    setDoesClarificationExist(true);
                    removeHighlightedText();
                  }}
                >
                  <TableCell width={"1%"}></TableCell>
                  <TableCell width={"20%"}>
                    {groundTruthField.fieldName}
                  </TableCell>
                  <AnswerCells
                    isBlind={isBlind}
                    randomizedOrder={randomizedFieldOutputOrders[index]}
                    groundTruthField={groundTruthField}
                    isAnswerMatch={isAnswerMatch}
                    isFieldMultiSelected={isFieldMultiSelected}
                    getAnswerLowerCaseAndSeparatebyComma={
                      getAnswerLowerCaseAndSeparatebyComma
                    }
                  />
                  <TableCell width={"20%"} alignmentHorizontal={"center"}>
                    <Row alignmentHorizontal={"center"}>
                      {isBlind ? (
                        <BlindReviewDecisionSelect
                          groundTruthField={groundTruthField}
                          shouldShowError={shouldShowError}
                          isAnswerMatch={isAnswerMatch}
                          isFieldMultiSelected={isFieldMultiSelected}
                          isAnswerInadequate={isAnswerInadequate}
                          getLabel={getLabel}
                          getConfirmedAnswer={getConfirmedAnswer}
                          onFieldConfirmedAnswerChange={(
                            fieldIndex,
                            confirmedAnswer,
                            reviewResult,
                          ) => {
                            dispatch(
                              setFieldConfirmedAnswer({
                                fieldIndex,
                                confirmedAnswer,
                                reviewResult,
                              }),
                            );
                          }}
                          fieldIndex={index}
                          isRandomized={randomizedFieldOutputOrders[index]}
                        />
                      ) : (
                        <ReviewDecisionSelect
                          groundTruthField={groundTruthField}
                          shouldShowError={shouldShowError}
                          isAnswerMatch={isAnswerMatch}
                          isFieldMultiSelected={isFieldMultiSelected}
                          isAnswerInadequate={isAnswerInadequate}
                          getLabel={getLabel}
                          getConfirmedAnswer={getConfirmedAnswer}
                          onFieldConfirmedAnswerChange={(
                            fieldIndex,
                            confirmedAnswer,
                            reviewResult,
                          ) => {
                            dispatch(
                              setFieldConfirmedAnswer({
                                fieldIndex,
                                confirmedAnswer,
                                reviewResult,
                              }),
                            );
                          }}
                          fieldIndex={index}
                        />
                      )}
                    </Row>
                  </TableCell>
                  <TableCell width={"20%"}>
                    <Row alignmentHorizontal={"center"}>
                      {selectedModel.id !== "" &&
                        showExtraOptions(groundTruthField) &&
                        getFinalAnswer(index)}
                    </Row>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Column>
      </Row>
    </Column>
  );
};

export default ModelGroundTruthReview;
